.market-layout {
  height: 100%; // commentado para que lo gris ocupe todo el height

  //background-color: #ffffff;
  .layout-container {
    background: linear-gradient(140.35deg,
        #ffffff 3.88%,
        #ffffff 22.03%,
        #d1d1d1 96.12%);
    height: 100%;
    padding: 0;

    .main-row {
      height: 100%;
    }
  }

  .appbar-column {
    // Move this to appbar component
    // background-color: #fcfcfc;
    border-left: 1px solid $gray-600;
  }

  .market-column-column {
    // background-color: #fcfcfc;
    height: 100%;
  }

  .col {
    height: 100%;
  }
}

.mock-size {
  min-height: 300px;
}

a.custom-button {
  text-decoration: none;
  color: #FFFFFF;
}

.custom-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 14px;
  box-sizing: border-box;
  /* Add this line */
}

.custom-button-theme {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 7px 14px;
  box-sizing: border-box;
  /* Add this line */
}

.with-icon {
  justify-content: space-around;
  width: 166px;
}

.text-container {
  margin-top: 55px;
  margin-left: 10px;
  width: 160px;
  position: absolute;
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  font-size: 22px;
  line-height: 46px;
  text-align: left;
  color: #6D7A8B;
}

.text-container-theme {
  margin-top: 55px;
  margin-left: 95px;
  width: 300px;
  position: absolute;
  font-family: Lato;
  font-style: regular;
  font-weight: 600;
  font-size: 22px;
  line-height: 46px;
  text-align: left;
  color: #6D7A8B;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 16px;
  position: absolute;
  min-width: 420px;
  /* Set a minimum width */
  max-width: 100%;
  /* Allow the container to grow to full width */
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  top: 110px;
  height: 130px;
  right: 35px;
}

.buttons-container-appstore {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 16px;
  max-width: 660px;
  /* Set a minimum width */
  /*  max-width: 41%; */
  /* Allow the container to grow to full width */
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  top: 110px;
  height: 140px;
  right: 35px;
}

.buttons-container-no-saveas {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 20px;
  position: absolute;
  min-width: 420px;
  /* Set a minimum width */
  max-width: 100%;
  /* Allow the container to grow to full width */
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  top: 110px;
  height: 130px;
  right: 40px;
}

.text-container-wrapper {
  position: absolute;
  top: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* changed from column to row */
  align-items: center;
  /* vertically align items in the middle */
}

.text-container,
.text-container-theme {
  font-family: Lato;
  font-size: 22px;
  line-height: 46px;
  text-align: left;
  color: #6D7A8B;
  width: 400px;
}

.text-container {
  font-style: italic;
  font-weight: 300;
  margin-right: 10px;
  /* Adjust as needed */
}

.text-container-theme {
  font-style: regular;
  font-weight: 600;
  line-height: 1.2;
}


@media screen and (max-width: 1450px) {
  .buttons-container {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    /* Five rows with equal height */
    min-width: 290px;
    top: 110px;
    right: 60px;
    height: 440px;
    /* Remove fixed height */
  }


  .buttons-container>*:nth-child(6),
  .buttons-container>*:nth-child(7) {
    grid-row: 5 / span 2;
    /* Place last two items in the fourth and fifth rows */
  }


  .buttons-container-appstore {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    /* Five rows with equal height */
    min-width: 290px;
    top: 110px;
    right: 60px;
    height: 250px;
    /* Remove fixed height */
  }

  .buttons-container-appstore>*:nth-child(6),
  .buttons-container-appstore>*:nth-child(7) {
    grid-row: 5 / span 2;
    /* Place last two items in the fourth and fifth rows */
  }
  
  .custom-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 100%;
  }

  
  .text-container-wrapper {
    position: absolute;    
    top: 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* changed from column to row */
    align-items: center;
    /* vertically align items in the middle */
  }

  .text-container {
    margin-top: 25px;
    margin-left: 30px;
    width: 100%;
    position: absolute;
    font-family: Lato;
    font-style: italic;
    font-weight: 300;
    font-size: 22px;
    text-align: left;
    color: #6D7A8B;

  }

  .text-container-theme {
    margin-top: 90px;
    margin-left: 30px;
    width: 290px;
    position: absolute;
    font-family: Lato;
    font-style: regular;
    font-weight: 600;
    font-size: 22px;
    line-height: 46px;
    text-align: left;
    color: #6D7A8B;
    line-height: 1.2;
  }

  .desc-container {
    display: none;
  }

  .element {
    transform: translateX(120%);
  }

}

@media screen and (max-width: 1450px) {
  .buttons-container-no-saveas {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    /* Five rows with equal height */
    min-width: 290px;
    top: 110px;
    right: 60px;
    height: 380px;
    /* Remove fixed height */
  }

  .buttons-container-no-saveas>*:nth-child(5),
  .buttons-container-no-saveas>*:nth-child(6) {
    grid-row: 4 / span 2;
    /* Place last two items in the fourth and fifth rows */
  }

  .custom-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 100%;
  }

  .text-container-wrapper {
    position: absolute;
    top: 60%;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* changed from column to row */
    align-items: center;
    /* vertically align items in the middle */
  }

  .text-container {
    margin-left: 30px;
    width: 100%;
    position: absolute;
    font-family: Lato;
    font-style: italic;
    font-weight: 300;
    font-size: 22px;
    text-align: left;
    color: #6D7A8B;

  }

  .text-container-theme {
    margin-left: 30px;
    width: 270px;
    position: absolute;
    font-family: Lato;
    font-style: regular;
    font-weight: 600;
    font-size: 22px;
    line-height: 46px;
    text-align: left;
    color: #6D7A8B;
    line-height: 1.2;
  }

  .desc-container {
    display: none;
  }

  .element {
    transform: translateX(120%);
  }

}



.save-button {
  background: #39d96e;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;

  /* Inside Auto Layout */
  /*flex: none;*/
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
  /*margin-bottom: 10px; */
}

.save-as-button {
  background: #5ac5e8;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
  margin-bottom: 10px;
  /* Add margin-bottom for spacing */
}



.themes-button {
  background: #1E94EC;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
  margin-bottom: 10px;
  /* Add margin-bottom for spacing */
}

.preview-button {
  background: #2c60a4;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
  margin-bottom: 10px;
  /* Add margin-bottom for spacing */
}

.howto-button {
  background: #2c60a4;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1), inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;
  cursor: pointer;
}


.publish-button {
  background: #4a47b0;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
  margin-bottom: 10px;
  /* Add margin-bottom for spacing */

}

.delete-button {
  background: #f5516b;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 31px;
}

.cancel-button {
  background: #acb7c5;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;
}

.save-modal-button {
  background: #acb7c5;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;
  margin-left: 10px;
}

.addtheme-button {
  flex-shrink: 0;
  background: #9932CC;
  /* button bevel and shad */

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 31px;
  margin-right: 20px;
}



.content-button {
  background: #0c92ef;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1),
    inset 0px -2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 31px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-action-link {
  border-radius: 8px;
  color: #ffffff;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 31px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-button.upload-image {
  margin: 0px 5px;
}

.content-counter {
  background: #000000;
  mix-blend-mode: multiply;
  opacity: 0.2;
  border-radius: 4px;
  position: absolute;
  width: 39px;
  right: 6px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  /* or 100% */

  text-align: center;
  letter-spacing: 0.6px;

  color: #ffffff;
}

.desc-container {
  position: absolute;
  width: 500px;
  height: 718px;
  right: 22px;
  top: 200px;
}

.descriptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 490px;
  height: 344px;
  left: 0px;
  top: 55px;

  .desc-one {
    /* Paragraph big */
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    /* or 102% */
    text-align: center;
    letter-spacing: -0.9px;

    /* Global/active section */
    color: #6d7a8b;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 14px 15px;

    a {
      color: #0c92ef;
    }

    a:hover {
      color: #0c92ef;
    }
  }

  .desc-two {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 32px;
    text-align: center;
    /* letter-spacing: -0.9px; */
    color: #788595;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 35px;
    margin-right: 35px;
  }

  .desc-three {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 26px;
    /* identical to box height, or 152% */

    letter-spacing: 0.0999999px;
    text-decoration-line: underline;

    /* buttons/primary */

    color: #2c60a4;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 5px;
    margin-top:30px;

    overflow: visible;

    cursor: pointer;
    
    /* Ajustes adicionales para el ícono */
  img {
    width: 35px; /* Ajusta el tamaño del ícono según sea necesario */
    height: auto; /* Mantén la proporción */
    margin-right: 8px; /* Añade un pequeño margen entre el ícono y el texto */
    vertical-align: middle; /* Alinea verticalmente */
  }

  }
}