@import "../common/variables";

.previewModal {
    &.isOpen {
        display: flex;
        .modal__container {
            background-color: $CCDC;
            padding: 30px;
            border-radius: $B10;
            position: relative;
            .row {
                justify-content: space-between;
                margin-bottom: 0px;
                margin-left: 0px;
                margin-right: 0px;            
                &.body__modal {
                    margin-top: 0;
                }
            }

            .modal__title {
                margin-left: 10px;   
                display: flex;
                width: auto;
                color: $CA8B;
                font-size: 28px;
                font-style: italic;
                font-weight: 300;

            }

            .modal__buttons {
                display: flex;
                align-items: center;
                white-space: nowrap;  
            }

            .close__button {                
                text-transform: uppercase;
                border: none;
            }

            .cancel-button {       
                margin-right: 10px;         
                text-transform: uppercase;
                border: none;
            }            

            .img__modal {
                background-repeat: no-repeat;

                display: flex;
                justify-content: flex-end;
                width: 393px;
                height: 393px;
            }

            .modal__container-image {
                margin-top: 20px;
                margin-left: 10px;
                //display: flex;
                justify-content: center;
                align-items: center;
            }

            .textPreview {
                margin-top: 0.875rem;
                font-size: 24px;
                font-weight: 300;
                text-align: center;
                line-height: 1.5;
            }

            .modal__link {
                text-decoration: underline;
            }
        }

    }
}