@import "../common/variables";

.modalVersion {
  align-items: center;
  justify-content: center;  
  z-index: 9999;
  
  .modal-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 14px;
    box-sizing: border-box;
    /* Add this line */
  }

  &.isOpen {
    display: flex;
    
    .modal__container {
      background-color: $CCDC;
      border-radius: 20px;
      position: relative;
      background-color: $CCDC;
      padding: 20px 30px 30px 30px;
      //overflow: hidden;
      /*height: 692px;*/

      .row {
        justify-content: space-between;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        &.body__modal {
          margin-bottom: 0;

          .table__head .head__th {
            border: none;
            font-weight: 700;
            font-family: lato;
            font-size: 19px;
            color: $CA8B;
            padding: 1rem;
            padding-left: 30px;
          }

          .table__head .head__th__update {
            border: none;
            font-weight: 700;
            font-family: lato;
            font-size: 19px;
            color: $CA8B;
            padding-left: 12px;
          }

          .body__background {           
            padding-inline: 1rem;
            border-radius: 10px;            
            background-color: white;
            width: 100%;
          }

          .body__background__video {           
            border-radius: 10px;            
            background-color: $CCDC;
            width: 100%;
          }

          .table {            
            border-radius: 10px;

            th {
              border: none;
            }

            td {
              vertical-align: middle;
              border: none;              
            }

            &__body {
              background-color: $CFFF;

              tr:not(:last-child) {
                border-bottom: 1px solid #6C8D93;
              }

              .item__title {
                font-size: 14px;
                font-family: lato;
                font-weight: 500;
                color: #6D7A8B;
                display: flex;
                align-items: center;

                &--selected {
                  color: #2F2D38;
                  font-size: 22px;
                  font-family: lato;
                  font-weight: 700;
                  
                }

                span {
                  
    font-size: 12px;
    padding-left: 1.5rem;
    color: #4A47B0;
    align-self: center; 
    font-weight: 700;
  }

  span::before {
    content: "• ";
    
  }
              }

              .span__container {               
                //width: 125px; 
                vertical-align: middle;  
                .container_span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 0vh; /* Adjust the height to fit your requirements */
                }
                
                .centered_span {
                  text-align: center;
                } 
              }

              .theme__container {               
                width: 100%;              
              }

              .day__container {               
                width: 135px;
                justify-content: center;
                display: flex;
                align-items: center;
                vertical-align: middle; 
              }
             

              .buttons__container {
                display: flex;
                justify-content: center;
                width: 580px;
                align-items: center;
                
                .buttonComponent.previewBlue {
                  white-space: nowrap; /* Impide que el texto salte de línea */
                  width: auto; /* Permite al botón expandirse según el contenido */
                  padding: 0.5rem 1rem; /* Agrega padding suficiente para darle espacio alrededor del texto */
                  flex-shrink: 0; /* Evita que el botón se reduzca en flexbox */
                  background-color: $C0A4 ;
                  color:  $CFFF;
                  -webkit-box-shadow: 0px 2px 0px 0px  $CF84;
                  box-shadow: 0px 2px 0px 0px  $CF84;
                  margin-left: 8px;
                }

                .buttonComponent {
                  font-size: 15px;
                  font-family: lato;
                }

  
              }

              .buttonComponent {
                border: none;
              }

            }

          }
        }
      }

      .modal__title {
        display: flex;
        width: auto;
        color: $CA8B;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        padding: 0 10px;


      }

      .modal__buttons {
        padding: 0 10px;

        display: flex;
        align-items: center;
      }

      .modal__buttons__themes {
        width: 350px;
        display: flex;
        justify-content: flex-end;
        padding: 1.4rem 1rem;
      }

      .close__button {
        text-transform: uppercase;
        border: none;
      }

    }

  }
}

.modalVideo {
  align-items: center;
  justify-content: center;  
  z-index: 9999;
  
  .modal-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 14px;
    box-sizing: border-box;
    /* Add this line */
  }

  &.isOpen {
    display: flex;
    
    .modal__container {
      background-color: $CCDC;
      border-radius: 20px;
      position: relative;
      background-color: $CCDC;
      padding: 20px 30px 30px 30px;
      //overflow: hidden;
      /*height: 692px;*/

      .row {
        justify-content: space-between;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        &.body__modal {
          margin-bottom: 0;

          .table__head .head__th {
            border: none;
            font-weight: 700;
            font-family: lato;
            font-size: 19px;
            color: $CA8B;
            padding: 1rem;
            padding-left: 30px;
          }

          .table__head .head__th__update {
            border: none;
            font-weight: 700;
            font-family: lato;
            font-size: 19px;
            color: $CA8B;
            padding-left: 12px;
          }

          .body__background {           
            padding-inline: 1rem;
            border-radius: 25px;            
            background-color: $CCDC;
            width: 100%;
          }

          .table {            
            border-radius: 00px;

            th {
              border: none;
            }

            td {
              vertical-align: middle;
              border: none;              
            }

            &__body {
              background-color:  $CCDC;

              tr:not(:last-child) {
                border-bottom: 1px solid #6C8D93;
              }

              .item__title {
                font-size: 14px;
                font-family: lato;
                font-weight: 500;
                color: #6D7A8B;
                display: flex;
                align-items: center;

                &--selected {
                  color: #2F2D38;
                  font-size: 22px;
                  font-family: lato;
                  font-weight: 700;
                  
                }

                span {
                  
    font-size: 12px;
    padding-left: 1.5rem;
    color: #4A47B0;
    align-self: center; 
    font-weight: 700;
  }

  span::before {
    content: "• ";
    
  }
              }

              .span__container {               
                //width: 125px; 
                vertical-align: middle;  
                .container_span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 0vh; /* Adjust the height to fit your requirements */
                }
                
                .centered_span {
                  text-align: center;
                } 
              }

              .theme__container {               
                width: 100%;              
              }

              .day__container {               
                width: 135px;
                justify-content: center;
                display: flex;
                align-items: center;
                vertical-align: middle; 
              }
             

              .buttons__container {
                display: flex;
                justify-content: center;
                width: 580px;
                align-items: center;
                .buttonComponent {
                  font-size: 15px;
                  font-family: lato;
                }

  
              }

              .buttonComponent {
                border: none;
              }

            }

          }
        }
      }

      .modal__title {
        display: flex;
        width: auto;
        color: $CA8B;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        padding: 0 10px;


      }

      .modal__buttons {
        padding: 0 10px;

        display: flex;
        align-items: center;
      }

      .modal__buttons__themes {
        width: 350px;
        display: flex;
        justify-content: flex-end;
        padding: 1.4rem 1rem;
      }

      .close__button {
        text-transform: uppercase;
        border: none;
      }

    }

  }
}


.modalSave {
  
  .modal-content {
    height: 240px;
    overflow: hidden;
}
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
  -webkit-box-shadow: 0px 2px 0px 0px 20px;

  .modal-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 7px 14px;
    box-sizing: border-box;
    /* Add this line */
  }

  &.isOpen {
    display: flex;

    .modal__container {
      /*width: 430px;*/
      background-color: $CCDC;
      padding-top: 30px;
      border-radius: 20px;
      position: relative;
      height: 233px;
      overflow: hidden;

      .input {
        background-color: $CA8B;
        box-shadow: 0px 3px 0px 0px #00000033 inset;
        padding: 0 10px;
        margin-bottom: 10px;
        margin-top: 20px;
        border-radius: 20px;
        font-size: 18px;
        line-height: 25px;
        color: $CFFF;
        width: 374px;
      }

      .modal__buttons {
        white-space: nowrap;  
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        margin-top: 10px;
        padding: 1.4rem 1rem;
        background-color: #fff;
        gap: 1rem;
      }
      
      .modal__title {
        display: flex;
        width: auto;
        color: $CA8B;
        font-size: 30px;
        font-style: italic;
        font-weight: 300;
        margin: 0 1rem;
        padding: 0 10px;

      }

      .close__button {
        text-transform: uppercase;
        border: none;
      }

    }
  }

}

// THEME LOADER DATA
.loading-content-theme {
  text-align: center;
}

.loading-container-theme {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40%;
  top: 5%;
  left: 15%;
  z-index: 9999;
}

.loading-container-theme  img {
  width: 20%;
  margin-top: 80px;
}

.loading-text-black {
  font-size: 18px;
  color: #6D7A8B;
  font-weight: 400;
  font-style: italic;  
  padding-left: 478px;
  padding-top: 300px;
}