@import '../common/variables';

.content-box {
    position: absolute;
    top: 160%;
    left: -200%;
    width: 680px;
    min-width: 680px;
    max-width: 700px;
    .--navbar & {
        left: -840%;
    }
    .--collection & {
        left: -215%;
    }
    .--header & {
        left: -490%;
    }
    .--list & {
        left: -1210%;
    }
    .--detailProduct & {
        left: -250%;
    }
    .--addToCart & {
        left: -450%
    }
    .--checkoutList & {
        left: -380%;
    }
    .--checkoutStepper & {
        left: -280%;
    }
    .--checkoutBottom & {
        left: -890%
    }
    .--globalStyle & {
        left: -235%;
    }
    .--globalLoaders & {
        left: -660%;
    }
    .box {
        border-radius: $B20;
        -moz-border-radius: $B20;
        -ms-border-radius: $B20;
        -o-border-radius: $B20;
        box-shadow: 0px 0px 15px 0px #00000026;
        padding:20px;
        background-color:  $CFFF;
        justify-content: space-between;
        align-items: center;
        -webkit-border-radius: $B20;
        position: relative;
        width: 100%;
        .paragraph{
            color:  $CA8B;
            width: 77%;
            display: flow-root;
        }
        &:after {
            content: "";
            display: block;
            width: 25px;
            height: 15px;
            background:  $CFFF;
            transform:rotate(45deg);
            -webkit-transform:rotate(45deg);
            -moz-transform:rotate(45deg);
            -ms-transform:rotate(45deg);
            -o-transform:rotate(45deg);
            -webkit-transform:rotate();
            position:absolute;
            top: -5px;
            left: 30%;
            .--header & {
                left: 45%;
            }
            .--collection & {
                left: 50%;
            }
            .--navbar & {
                left: 56%;
            }
            .--list & {
                left: 67%;
            }
            .--detailProduct & {
                left: 48%;
            }
            .--addToCart & {
                left: 68%;
            }
            .--checkoutList & {
                left: 46%;
            }
            .--checkoutStepper & {
                left: 65%;
            }
            .--checkoutBottom & {
                left: 84%;
            }
            .--globalStyle & {
                left: 49%;
            }
            .--globalLoaders & {
                left: 66%;
            }
        }
        .buttonComponent{
            border: none;
            &.edit {
                margin-left: 0;
            }
        }
    }
}
 
@media screen and (max-width: 1500px) {
   
.content-box {
    position: absolute;
    top: 160%;
    left: -200%;
    width: 680px;
    min-width: 680px;
    max-width: 700px;
    .--navbar & {
        left: -820%;
    }
    .--collection & {
        left: -215%;
    }
    .--header & {
        left: -490%;
    }
    .--list & {
        left: -1210%;
    }
    .--detailProduct & {
        left: -250%;
    }
    .--addToCart & {
        left: -450%
    }
    .--checkoutList & {
        left: -380%;
    }
    .--checkoutStepper & {
        left: -280%;
    }
    .--checkoutBottom & {
        left: -890%
    }
    .--globalStyle & {
        left: -235%;
    }
    .--globalLoaders & {
        left: -660%;
    }
    .box {
        border-radius: $B20;
        -moz-border-radius: $B20;
        -ms-border-radius: $B20;
        -o-border-radius: $B20;
        box-shadow: 0px 0px 15px 0px #00000026;
        padding:20px;
        background-color:  $CFFF;
        justify-content: space-between;
        align-items: center;
        -webkit-border-radius: $B20;
        position: relative;
        width: 100%;
        .paragraph{
            color:  $CA8B;
            width: 72%;
            display: flow-root;
        }
        &:after {
            content: "";
            display: block;
            width: 25px;
            height: 15px;
            background:  $CFFF;
            transform:rotate(45deg);
            -webkit-transform:rotate(45deg);
            -moz-transform:rotate(45deg);
            -ms-transform:rotate(45deg);
            -o-transform:rotate(45deg);
            -webkit-transform:rotate();
            position:absolute;
            top: -5px;
            left: 30%;
            .--header & {
                left: 51%;
            }
            .--collection & {
                left: 58%;
            }
            .--navbar & {
                left: 64%;
            }
            .--list & {
                left: 78%;
            }
            .--detailProduct & {
                left: 56%;
            }
            .--addToCart & {
                left: 80%;
            }
            .--checkoutList & {
                left: 54%;
            }
            .--checkoutStepper & {
                left: 76%;
            }
            .--checkoutBottom & {
                left: 95%;
            }
            .--globalStyle & {
                left: 57%;
            }
            .--globalLoaders & {
                left: 77%;
            }
        }
        .buttonComponent{
            border: none;
            &.edit {
                margin-left: 0;
            }
        }
    }
}
  }

.tagSelect {
    background-image: url(../../img/arrowSelect.png) !important;
    background-repeat: no-repeat !important;
    background-position: 95% center !important;
    background-size: 10px !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
    position: relative !important;
    font-weight: 300;
    font-size: 18px;
    color: $CFFF;
    padding: 4px 12px; 
    box-shadow: inset 0px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    background:  $CA8B;
    position: relative;
    min-width: 190px;
    .--detailProduct  & {
        margin-left: 65px;
    }
}